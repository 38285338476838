class FoldableMenuComponent extends HTMLElement {
    #elTogglers = this.querySelectorAll('.js-toggler');

    constructor() {
        super();
        this.bindEvents();
    }

    bindEvents() {
        for (const el of this.#elTogglers) {
            el.addEventListener('click', () => {
                el.parentElement.classList.toggle('active');
            });
        }
    }
}

customElements.define('foldable-menu', FoldableMenuComponent);