(() => {
    class PopoverMenuManager {
        #instances = [ ];
        #current = null;

        constructor() {
            this.init();
        }

        init() {
            document.body.addEventListener('click', (e) => {
                if (!(this.#instances.some(i => i.contains(e.target)))) {
                    this.toggle(null);
                }
            })
        }

        register(menu) {
            this.#instances.push(menu);
        }

        toggle(menu) {
            if (this.#current === menu) {
                this.#current = null;
            } else {
                this.#current = menu;
            }
            this.update();
        }

        update() {
            for (const instance of this.#instances) {
                instance.update(this.#current === instance);
            }
        }
    }

    const manager = new PopoverMenuManager;

    class PopoverMenuComponent extends HTMLElement {
        #elToggler = this.querySelector('.js-popover-menu-toggler');

        constructor() {
            super();
            manager.register(this);
            this.bindEvents();
        }

        bindEvents() {
            this.#elToggler.addEventListener('click', () => {
                manager.toggle(this);
            });
        }

        update(status) {
            this.classList.toggle('popover-menu--open', status);
        }
    }

    customElements.define('popover-menu', PopoverMenuComponent);
})()